import React, {useState, useEffect} from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import { Container } from "styled-bootstrap-grid"
import breakpoints from "../styles/breakpoints"
import BackgroundImage from "gatsby-background-image"
import colors from "../styles/colors"

const Hero = styled.div`
  height: calc(86vh - 52px);
  width: 100%;
  border-bottom: 1px solid ${colors.black};
  @media (min-width: ${breakpoints.lg}) {
    height: calc(100vh - 52px);
  }
`

const HeroDesktopImg = styled(BackgroundImage)`
  display: none;
  width: 100%;
  height: 100%;
  opacity: 1 !important;
  &:after {
    background-size: cover;
    background-position: center;
  }
  &:before {
    background-size: cover;
    background-position: center;
  }
  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`

const HeroMobileImg = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  opacity: 1 !important;
  
  &:after {
    background-size: cover;
    background-position: center;
  }
  
  &:before {
    background-size: cover;
    background-position: center;
  }
  
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const IndexPage = (props) => {
  const path = props.location.pathname
  const page = props.data.allContentfulHomepage.edges[0].node
  const heroDesktop = page.heroDesktop
  const heroMobile = page.heroMobile
  // const [height, setHeight] = useState("100vh")

  // useEffect(() => {
  //   if (typeof window !== undefined) {
  //     setHeight(window.innerHeight)
  //     window.addEventListener('resize', () => setHeight(window.innerHeight));
  //   }
  // }, [])

  return (
    <Layout whiteIcons={page.configuration}>
      <SEO
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={false}
        path={path}/>
      <Container fluid>
        <Hero className={"hero"}>
          <HeroDesktopImg fluid={heroDesktop.fluid} />
          <HeroMobileImg fluid={heroMobile.fluid}/>
        </Hero>
      </Container>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomepageQuery {
    allContentfulHomepage(limit: 1) {
      edges {
        node {
          id
          metaDescription
          metaTitle
          configuration
          heroDesktop {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          heroMobile {
            fluid(maxWidth: 750, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`
